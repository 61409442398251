import React from 'react';
import HomeSection from '../HomeSection';
import '../../App.css'


function Home() {
    return (
    <>
        <HomeSection/>
    </>
    );
}

export default Home;