import React from 'react';
import GallerySection from '../GallerySection';
import '../../App.css'


function Gallery() {
    return (
    <>
        <GallerySection/>
    </>
    );
}

export default Gallery;