import React from 'react';
import ProjectsSection from '../ProjectsSection';
import '../../App.css'


function Projects() {
    return (
    <>
        <ProjectsSection/>
    </>
    );
}

export default Projects;