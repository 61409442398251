import '../../App.css'
import React from 'react';
import ContactSection from '../ContactSection';

function Contact() {
    return (
        <>
            <ContactSection />
        </>
    );
}

export default Contact;