import React from 'react';
import '../App.css';
import './Footer.css';


function Footer() {
    return (
    <div className='footer'>
        <p>Sean Hershey © 2024</p>
    </div>
    );
}

export default Footer;